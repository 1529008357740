import React, { useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import _ from 'lodash';
import { Grid } from '@material-ui/core';


const Item = ({ src, alt}) => (
    <img src={src} alt={alt} width="100%"/>
)

const CarouselMobile = ({imgs = [], title='default', className}) => {

    const [value, setValue] = useState(0);
    const [data, setData] = useState(imgs.length > 0 ? _.head(imgs) : '');

    const handleChange = (value) => {
        let img = imgs.filter((img) => img.id == value + 1)[0];
        setData(img);
        setValue(value);
    }

    return(
        <Grid 
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={className}
        >
            <Grid item md={12}>
            <h1 className="main-title">{title}</h1>
            </Grid>
            <Grid container item md={5} xs={12} spacing={1} style={{ display:'block' }}>
                <Grid item md={12}>
                    <div>
                    <Carousel
                    value={value}
                    onChange={handleChange}
                    arrows
                    >
                        {
                            imgs && imgs.map((img, index) => (
                                <Item src={img.src} alt={img.alt} key={index}/>
                            ))
                        }
                    </Carousel>
                    </div>
                </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
                <div style={{ margin:'auto', maxWidth:'450px' }}>
                <h1 className="subtitle">{data.title}</h1>
                <p>{data.description}</p>
                </div>
            </Grid>
        </Grid>
    );
}

export default CarouselMobile;