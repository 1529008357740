import { Grid } from '@material-ui/core';
import React from 'react';

const Carousel = ({ mainImg, onClick, imgs = [], title="default", width = '100%', className }) => {
    return(
    <Grid 
    container
    direction="row"
    justify="center"
    alignItems="center"
    className={className}
    >
        <Grid item md={12}>
        <h1 className="main-title">{title}</h1>
        </Grid>
        <Grid container item md={5} xs={12} spacing={1}>
            <Grid item md={12} style={{ textAlign:'center' }}>
                <img src={mainImg.src} alt="main-img" width={width}/>
            </Grid>
            {
                imgs && imgs.map((img, index)=>(
                    <Grid item md={4} key={index} className="mobile">
                        <img src={img.src} alt={index} width={width} onClick={()=>onClick(img.id)}/>
                    </Grid>
                ))
            }
        </Grid>
        <Grid item md={6} xs={12}>
            <div style={{ margin:'auto', maxWidth:'450px' }}>
            <h1 className="subtitle">{mainImg.title}</h1>
            <p>{mainImg.description}</p>
            </div>
        </Grid>
    </Grid>
    )
}

export default Carousel;