import hogar from '../images/Hogar.png';
import manteleria from '../images/Manteleria.png';
import hospital from '../images/Hospital.png';
import hotel from '../images/Hotel.png';

const categories = [
    {
        id:1,
        title:'HOGAR',
        description:"Con nuestra amplia gama de productos de alta calidad y durabilidad, usted podrá personalizar el estilo de su hogar. Con blancos DIBLAGA logrará que sus espacios tengan esos toques de peculiaridad, confort y elegancia que usted busca, ya que contamos con una gran variedad de productos desde sábanas, protectores, toallas, cobijas almohadas, colchas etc.",
        src:hogar,
        isActive:true,
    },
    {
        id:2,
        title:'MANTELERÍA',
        description:"En nuestra línea de mantelería usted podrá encontrar productos como servilletas, caminos, bambalinas, forros de sillas, manteles y más, todo para lograr la elegancia y protección necesaria para su mobiliario, obteniendo así la experiencia deseada para todos los comensales.",
        src:manteleria,
        isActive:false,
    },
    {
        id:3,
        title:'HOSPITAL',
        description:"Con excelente calidad y hechos con las especificaciones requeridas, usted podrá encontrar en nuestra línea hospitalaria productos como bultos quirúrgicos, campos, batas de paciente, sábanas, almohadas hospitalarias, toallas y más, brindando calidad, confort e higiene para sus pacientes.",
        src:hospital,
        isActive:false,
    },
    {
        id:4,
        title:'HOTELERIA Y MOTELERIA',
        description:`"Nuestros productos para hoteles y moteles nos han catalogado como una de las mejores empresas en el giro, ofreciendo en ellos la calidad, variedad y durabilidad deseada, considerando también nuestros precios inigualables. 
        Nuestros clientes nos consideran como un proveedor seguro y confiable en productos como sábanas, fundas, almohadas, protectores, colchas, cortinas, cojines, caminos, toallas, batas y más; generando que los espacios de su hotel o motel tengan el toque necesario para logar diferenciarse de la competencia, brindando la armonía de una habitación elegante y cómoda. 
        Considerando todos estos puntos, es como nos vuelve el proveedor idóneo de blancos."`,
        src:hotel,
        isActive:false,
    }
];

export default categories;