import { Container, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Carousel from '../../components/Carousel';
import categoriesJs from '../../const/categories';
import Layout from '../../layout';
import _ from 'lodash';
import CarouselMobile from '../../components/CarouselMobile';

const useStyles = makeStyles((theme) => (
    {
        heightMobile:{
            height:'auto',
            paddingBottom:'2rem',
        },
        desktop:{
            [theme.breakpoints.down('sm')]:{
                display: 'none',
            }
        },
        mobile:{
            display:'none',
            [theme.breakpoints.down('sm')]:{
                display: 'block',
            }
        }
    }
))

const Categorias = () => {
    const classes = useStyles();
    const [ categories, setCategories ] = useState(categoriesJs);

    const handleImg = (id) => {
        let activeIndex = _.findIndex(categories,(category) => category.isActive === true);
        let index = _.findIndex(categories,(category) => category.id === id);
        let temp = [...categories];
        temp[activeIndex] = { ...temp[activeIndex], isActive:false };
        temp[index] = { ...temp[index], isActive: !temp[index].isActive }; //187.190.125.78 Aguas <- 187.190.63.104 Cancun X 189.203.74.251 tepic <-
        setCategories([...temp]);
    }

    return (
        <Layout footer>
            <Container className="mb-2">
                <Carousel 
                className={classes.desktop}
                title="Categorías"
                width="80%"
                mainImg={categories.filter((category) => category.isActive)[0]} 
                onClick={handleImg} 
                imgs={categories.filter((category) => !category.isActive)}/>
                <CarouselMobile
                    title="Proyectos"
                    className={classes.mobile}
                    imgs={categoriesJs}
                />
            </Container>
        </Layout>
    );
}

export default Categorias;